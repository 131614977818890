import React from "react";

const ProductSort = ({ sortValue, setSortValue }) => {
  const handleSortChange = (event) => {
    setSortValue(event.target.value);
  };

  return (
    <div className="d-flex justify-content-end mb-2">
      <p className="mb-0">Sort By:</p>
      <select className="ml-2" value={sortValue} onChange={handleSortChange}>
        <option value="asc">Ascending</option>
        <option value="desc">Descending</option>
        <option value="price_asc">Price-Ascending</option>
        <option value="price_desc">Price-Descending</option>
      </select>
    </div>
  );
};

export default ProductSort;