import React, { useState } from "react";
import { useSelector } from "react-redux";
import { contactUsSubmit } from "../../service/apiService";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    message: "",
    name: "",
    email: "",
    phone_no: "",
  });
  const [errors, setErrors] = useState({});
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const settingsData = useSelector((state) => state.settings.settingsData);

  const addressLinkOne = settingsData.find(
    (item) => item.key_name === "ADDRESS_ONE"
  )?.key_value;
  const phoneNumberone = settingsData.find(
    (item) => item.key_name === "PHONE_ONE"
  )?.key_value;
  const phoneNumbertwo = settingsData.find(
    (item) => item.key_name === "PHONE_TWO"
  )?.key_value;
  const emailOne = settingsData.find(
    (item) => item.key_name === "EMAIL_ONE"
  )?.key_value;
  const emailTwo = settingsData.find(
    (item) => item.key_name === "EMAIL_TWO"
  )?.key_value;
  const locationMapLink = settingsData?.find(
    (item) => item.key_name === "LOCATE_MAP_LINK"
  )?.key_value;
  const contactBackgroundImage = settingsData?.find(
    (item) => item.key_name === "CONTACT_US_BG_IMG"
  )?.key_value;
  console.log(locationMapLink);


  const validate = () => {
    let tempErrors = {};

    if (!formData.message) tempErrors.message = "Comment is required.";
    if (!formData.name) {
      tempErrors.name = "Name is required.";
    } else if (!/^[A-Za-z ]+$/.test(formData.name)) {
      tempErrors.name = "Name must contain only letters.";
    }
    if (!formData.email) {
      tempErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Email address is invalid.";
    }
    if (!formData.phone_no) {
      tempErrors.phone_no = "Phone number is required.";
    } else if (!/^\d{10}$/.test(formData.phone_no)) {
      tempErrors.phone_no = "Phone number is invalid.";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/[^A-Za-z0-9\s]/g, '');

  setFormData({ ...formData, [name]: sanitizedValue.replace(/^\s+/, "") });
};

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      // If form is valid, proceed to submit data
      const newformData = {
        ...formData,
        phone_no: parseInt(formData.phone_no),
      };
      contactUsSubmit(newformData);

      // Reset form fields
      setFormData({ message: "", name: "", email: "", phone_no: "" });
    }
  };

  return (
    <main className="main">
      <div
        className="page-header mt-2"
        style={{
          backgroundImage: `url(${AppUrl}${contactBackgroundImage})`,
          height: "150px",
        }}
      >
        <h1 className="page-title font-weight-bold text-capitalize ls-l text-dark">
          Contact Us
        </h1>
      </div>
      <div className="page-content mt-5 pt-7">
        <section className="contact-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6 ls-m mb-4 ">
                <div className="grey-section d-flex align-items-center h-100 px-3 ">
                  <div>
                    <h4 className="mb-2 text-capitalize mt-4">Address</h4>
                    <div
                      dangerouslySetInnerHTML={{ __html: addressLinkOne }}
                    ></div>
                    <h4 className="mb-2 text-capitalize">Phone Number</h4>
                    <p>
                      <a href="tel:#">{phoneNumberone}</a>
                      <br />
                      <a href="tel:#">{phoneNumbertwo}</a>
                    </p>
                    <h4 className="mb-2 text-capitalize">Mail</h4>
                    <p className="mb-4">
                      <a href="#">{emailOne}</a>
                      <br />
                      <a href="#">{emailTwo}</a>
                      <br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8 col-sm-6 d-flex align-items-center mb-4">
                <div className="w-100">
                  <form className="pl-lg-2" onSubmit={handleSubmit}>
                    <h4 className="ls-m font-weight-bold">Let’s Connect</h4>
                    <p>
                      Your email address will not be published. Required fields
                      are marked *
                    </p>
                    <div className="row mb-2">
                      <div className="col-12 mb-4">
                        <textarea
                          className="form-control"
                          required=""
                          minLength={10}
                          placeholder="Comment*"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                        />
                        {errors.message && (
                          <p className="text-danger">{errors.message}</p>
                        )}
                      </div>
                      <div className="col-12 mb-4">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Name *"
                          //  pattern="[A-Za-z]+"
                          minLength={4}
                          required=""
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                        {errors.name && (
                          <p className="text-danger">{errors.name}</p>
                        )}
                      </div>

                      <div className="col-md-6 mb-4">
                        <input
                          className="form-control"
                          type="email"
                          placeholder="Email *"
                          required=""
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {errors.email && (
                          <p className="text-danger">{errors.email}</p>
                        )}
                      </div>
                      <div className="col-md-6 mb-4">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Phone No *"
                          required=""
                           pattern="[0-9]*"
                          maxLength={10}
                          minLength={10}
                          name="phone_no"
                          value={formData.phone_no}
                          onChange={handleChange}
                        />
                        {errors.phone_no && (
                          <p className="text-danger">{errors.phone_no}</p>
                        )}
                      </div>
                    </div>
                    <button className="btn btn-dark btn-rounded">Submit</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="grey-section google-map" style={{ height: 386 }}>
          <iframe
            src={locationMapLink}
            style={{
              border: 0,
              width: "100%",
              minHeight: "100%",
              marginBottom: "-8px",
            }}
            allowFullScreen=""
          />
        </div>
      </div>
    </main>
  );
};

export default ContactUs;
