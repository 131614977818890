import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const PaginationIndex = ({
  totalProducts,
  productsPerPage,
  currentPage,
  handlePageChange,
  handleNextPage,
  handlePreviousPage,
  gotoFirstPage,
}) => {
  const totalPages = Math.ceil(totalProducts / productsPerPage);
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  const lessproduct = totalProducts < 20;

  return (
    <div className="row pgntn_par">
      <div className="d-flex align-items-center  flex-wrap justify-content-center">
        <div
          className="d-flex align-items-center me-3 "
          role="button"
          onClick={gotoFirstPage}
        >
          <FontAwesomeIcon icon={faAngleDoubleLeft} size="sm" color="	#A8A8A8" />
          <span className="ps-2 text-primary">page 1</span>
        </div>
        <div className="page_number">
          <button
            // to={`/shop?page=${currentPage - 1}`}
            className="prev content-prev"
            onClick={handlePreviousPage}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
            <span className="ps-2">Previous</span>
          </button>
          <div>
            <div className="page_count">
              Page {currentPage} of {totalPages}
            </div>
          </div>
            <button
              className="next content-next"
              onClick={handleNextPage}
              disabled={lessproduct}
            >
              <span className="pe-2">Next</span>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
        
        </div>
      </div>
    </div>
  );
};

export default PaginationIndex;
