import React, { useEffect, useState } from "react";

const PriceFilter = ({ priceRangeData, priceFilterOnchange }) => {
  // Initialize state for min and max price
  const minimum = priceRangeData.min_price_amount;
  const maximum = priceRangeData.max_price_amount;
  const [minPrice, setMinPrice] = useState(minimum);
  const [maxPrice, setMaxPrice] = useState(maximum);

  useEffect(() => {
    setMinPrice(priceRangeData.min_price_amount);
    setMaxPrice(priceRangeData.max_price_amount);
  }, [priceRangeData]);

  // Event handler for input changes in range inputs
  const handleRangeInputChange = (e) => {
    priceFilterOnchange(e);
    const { name, value } = e.target;
    if (name === "range-min") {
      if (parseInt(value) <= maxPrice) {
        setMinPrice(parseInt(value));
      }
    } else if (name === "range-max") {
      if (parseInt(value) >= minPrice) {
        // alert()
        setMaxPrice(parseInt(value));
      }
    }
  };

  // Calculate percentage for slider progress
  const rangeStyle = {
    left: ((minPrice - 1000) / maximum) * 100 + "%",
    right: 100 - (maxPrice / maximum) * 100 + "%",
  };

  return (
    <div className="">
      <div className="wrapper">
        <div className="rangeslider">
          <div className="progress" style={rangeStyle}></div>
        </div>
        <div className="range-input">
          <input
            type="range"
            name="range-min"
            className="range-min"
            min={minimum}
            max={maximum}
            value={minPrice}
            // step="100"
            onChange={handleRangeInputChange}
          />
          <input
            type="range"
            name="range-max"
            className="range-max"
            min={minimum}
            max={maximum}
            value={maxPrice}
            // step="100"
            onChange={handleRangeInputChange}
          />
        </div>
        <div className="price-input d-flex justify-content-between ">
          <div>
            <span>Min</span>{" "}
            <span>
              <strong>Rs. {Math.round(minPrice)}</strong>
            </span>
          </div>
          <div style={{ textAlign: "right" }}>
            <span>Max</span>{" "}
            <span>
              <strong>Rs. {Math.round(maxPrice)}</strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceFilter;
